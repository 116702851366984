<script setup lang="ts">
const props = defineProps({
    attrs: {
        default: () => ({}),
        type: Object as PropType<string | any>,
    },
    autocomplete: {
        default: true,
        type: Boolean,
    },
    disabled: {
        default: false,
        type: Boolean,
    },
    error: {
        default: null,
        type: String,
    },
    fullWidth: {
        default: true,
        type: Boolean,
    },
    highlighted: {
        default: false,
        type: Boolean,
    },
    icon: {
        default: null,
        type: String,
    },
    info: {
        default: null,
        type: String,
    },
    infoLabel: {
        default: 'Hinweis',
        type: String,
    },
    infoShowFrom: {
        default: '',
        type: String as PropType<'md' | 'lg' | ''>,
    },
    infoShowUntil: {
        default: '',
        type: String as PropType<'md' | 'lg' | ''>,
    },
    label: {
        default: null,
        type: String,
    },
    limit: {
        default: null,
        type: Number,
    },
    max: {
        default: null,
        type: String,
    },
    min: {
        default: null,
        type: String,
    },
    modelValue: {
        default: () => '',
        type: [String, Number, null] as PropType<string | number | null>,
    },
    name: {
        default: 'input',
        type: String,
    },
    pattern: {
        default: null,
        type: String,
    },
    placeholder: {
        default: '',
        type: String,
    },
    readonly: {
        default: false,
        type: Boolean,
    },
    type: {
        default: 'input',
        type: String,
    },
});

const emit = defineEmits(['update:modelValue']);

const id = useId();
const value = ref(props.modelValue);

watch(value, () => {
    emit('update:modelValue', value.value);
});

watch(
    () => props.modelValue,
    () => {
        value.value = props.modelValue;
    },
);
</script>

<template>
    <label
        :for="id"
        :data-invalid-field="error || null"
        class="block text-left"
        :class="[fullWidth ? 'w-auto' : 'lg:max-w-[370px]']"
    >
        <div v-if="label || info" class="mb-2 flex gap-4">
            <JamBaseText
                v-if="label"
                class="text-gray-600"
                variant="small"
                :title="label"
                :is-label="true"
            >
                {{ label }}
            </JamBaseText>
            <div
                class="ms-auto shrink-0"
                :class="[
                    infoShowUntil ? `block ${infoShowUntil}:hidden` : '',
                    infoShowFrom ? `hidden ${infoShowFrom}:block` : '',
                ]"
            >
                <JamBaseTooltip v-if="info || $slots.info">
                    <template #info><slot name="info" />{{ info }}</template>
                    <JamBaseText
                        class="cursor-help border-b border-dashed border-gray-600 text-gray-600 hover:text-gray-900"
                        variant="small"
                    >
                        {{ infoLabel }}
                    </JamBaseText>
                </JamBaseTooltip>
            </div>
        </div>

        <div
            class="flex items-center gap-2 rounded border border-gray-300 bg-white p-3 has-[input:disabled]:cursor-not-allowed has-[input:active]:border-gray-900 has-[input:focus]:border-gray-900 has-[input:invalid]:border-red-700 has-[input:disabled]:opacity-40 has-[input:focus-visible]:outline has-[input:focus-visible]:outline-2 has-[input:focus-visible]:outline-offset-2 has-[input:focus-visible]:outline-blue-600"
            :class="[
                error && error !== '' && 'border-red-700',
                fullWidth ? 'w-auto' : 'lg:max-w-[210px]',
                highlighted && 'border-yellow-600',
            ]"
        >
            <slot name="before-input"></slot>

            <JamBaseIcon
                v-if="icon"
                :icon-name="icon"
                stroke="thick"
                class="text-gray-600"
            />
            <input
                :id="id"
                v-model="value"
                v-bind="attrs"
                :name="name"
                :type="type"
                :disabled="disabled"
                :placeholder="placeholder"
                :min="min"
                :max="max"
                :maxlength="limit"
                :pattern="pattern"
                :readonly="readonly"
                :autocomplete="autocomplete ? 'on' : 'off'"
                class="w-full grow bg-white p-2 text-gray-900 placeholder:text-gray-400 focus:outline-none focus-visible:outline-none"
            />

            <slot name="after-input"></slot>
        </div>
        <transition
            enter-active-class="transition-all duration-300 ease-in"
            enter-from-class="max-h-0 opacity-0"
            enter-to-class="max-h-[100px]"
            leave-active-class="transition-all duration-300 ease-out"
            leave-from-class="max-h-[100px]"
            leave-to-class="max-h-0 opacity-0"
        >
            <JamBaseText v-if="error" variant="small" class="mt-2 text-red-700">
                {{ error }}
            </JamBaseText>
        </transition>
    </label>
</template>
